<template>
    <v-app>
        <v-container fluid fill-height id="login-page">
            <v-layout align-center justify-center>
                <v-flex :style="{ 'max-width': '450px' }">
                    <v-card
                        prepend-icon="mdi-home"

                    >
                        <v-img
                            :src="require('@/assets/Logo Laborde.jpg')"
                            contain
                            aspect-ratio="2"

                        ></v-img>
                        <v-row>
                            <v-col>
                                <v-img
                                    :src="require('@/assets/iso9001.jpg')"
                                    contain
                                    aspect-ratio="2"

                                ></v-img>
                            </v-col>
                            <v-col>
                                <v-img
                                    :src="require('@/assets/iso14001.jpg')"
                                    contain
                                    aspect-ratio="2"

                                ></v-img>
                            </v-col>
                        </v-row>
                        <v-card-title>
                            Laborde S.L. Cuadros Eléctricos
                            <v-row>
                                <v-col>
                                    <h6>
                                        - Av. de la técnica, 41. 28522. Rivas - Vaciamadrid.
                                    </h6>
                                    <h6>
                                        - <a href = "mailto: dpto.tecnico@cuadroselectricoslaborde.es">dpto.tecnico@cuadroselectricoslaborde.es</a> / <a href="tel:+34648023954">648 023 954</a>
                                    </h6>
                                </v-col>
                            </v-row>
                        </v-card-title>

                        <v-card-text
                            v-if="showData"
                        >
                            <v-text-field
                                readonly
                                label="Referencia"
                                v-model="panelData.reference"
                            />
                            <v-text-field
                                readonly
                                label="Versión"
                                v-model="panelData.documentationVersion"
                            />
                            <!--{{ panelData }}-->
                        </v-card-text>
                        <!--<v-list
                            v-if="showData"
                            subheader
                            two-line
                            style="margin-top: -20px"
                        >
                            <v-subheader>Files</v-subheader>

                            <v-list-item
                                v-for="file in panelData.files"
                                :key="file.fileName"
                                @click="menuActionClick(file.url)"
                            >
                                <v-list-item-avatar>
                                    <v-icon color="red">{{ fileIcon(file.fileName) }}</v-icon>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title v-text="file.fileName"></v-list-item-title>
                                    <v-list-item-subtitle v-text="file.fileName"></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-icon color="grey lighten-1">mdi-arrow-down-bold-circle</v-icon>
                                </v-list-item-action>
                            </v-list-item>

                            <v-divider inset></v-divider>
                        </v-list>-->
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="folder in panelData.folders"
                                :key="folder.name"
                            >
                                <v-expansion-panel-header>
                                    {{ folder.name }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-list
                                        v-if="showData"
                                        subheader
                                        two-line
                                        style="margin-top: -20px"
                                    >
                                        <v-list-item
                                            v-for="file in folder.files"
                                            :key="file.fileName"
                                            @click="menuActionClick(file.url)"
                                        >
                                            <v-list-item-avatar>
                                                <v-icon color="red">{{ fileIcon(file.fileName) }}</v-icon>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="file.fileName"></v-list-item-title>
                                                <v-list-item-subtitle v-text="file.fileName"></v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-action>
                                                <v-icon color="grey lighten-1">mdi-arrow-down-bold-circle</v-icon>
                                            </v-list-item-action>
                                        </v-list-item>

                                        <v-divider inset></v-divider>
                                    </v-list>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-card-subtitle>
                            <!--Lorem ipsum dolor sit amet consectetur adipiscing elit luctus augue curae, sem nascetur odio ac torquent dictumst nunc mattis metus, taciti per vel scelerisque dictum blandit elementum nec tempor. Odio auctor congue lobortis lacus lectus augue purus porttitor vitae hendrerit libero nec, class pulvinar quis malesuada sociosqu pharetra mollis ultricies varius per gravida justo tortor, duis ut convallis tristique neque facilisis mattis cras taciti sed nam. Dapibus fringilla tristique metus vehicula maecenas mus auctor cubilia curae aliquet ullamcorper, porttitor lacinia gravida interdum purus montes lobortis vulputate conubia.-->
                        </v-card-subtitle>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </v-app>
</template>


<script>
export default {
    name: "App",
    created() {
        let hash = this.$route.params.hash;
        if (hash) {
            let url = (process.env.VUE_APP_API_URL || "") + "/public/panel-info/" + hash
            fetch(url, {
                method: "GET"
            })
                .then((response) => response.json())
                .then((json) => {
                    this.panelData = json.result
                    this.showData = true
                })
            ;
        }
    },
    data: function () {
        return {
            panelData: {},
            showData: false
        }
    },
    methods: {
        menuActionClick(url) {
            window.open(url, '_blank');
        },
        fileIcon(fileName) {
            let d = fileName.toLowerCase();
            if (d.endsWith("pdf")) {
                return "mdi-file-pdf-box"
            } else if (d.endsWith("png") || d.endsWith("jpg") || d.endsWith("jpeg")) {
                return "mdi-image"
            } else {
                return "mdi-file"
            }
        }
    }
}
</script>
